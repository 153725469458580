import { GenericModal } from '@/components/Modal';
import { SelectCardOptions } from '@/pages/InvestorReporting/components/modals/utils';
import { useState } from 'react';
import { Button, Input, Textarea } from 'react-daisyui';

export default function CustomCardModal({
  isOpen,
  closeModal,
  onConfirm,
}: {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: (customCard: SelectCardOptions) => void;
}) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  return (
    <GenericModal onClose={closeModal} isOpen={isOpen}>
      <h1 className='font-bold'>Custom Data Card Builder</h1>
      <br />
      <div className='flex items-center'>
        <span className='mr-2'>Title:</span>
        <Input
          size='md'
          type='text'
          className='border-accent w-full'
          required
          data-testid='custom-card-title'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder='Title'
        />
      </div>
      <br />
      <div className='flex items-center'>
        <span className='mr-2'>Description:</span>
        <Textarea
          className='border-accent w-full p-2 bg-gray-800 text-white mt-5'
          value={description}
          rows={5}
          placeholder='Description'
          data-testid='custom-card-description'
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <br />
      <div className='flex w-full justify-center'>
        <Button
          type='submit'
          title='login'
          color='accent'
          variant='outline'
          size='sm'
          className='btn btn-accent bg-transparentp-2 m-2 ml-0'
          data-testid='cancel-custom-request-button'
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          title='login'
          color='accent'
          size='sm'
          className='btn btn-accent bg-transparentp-2 m-2 ml-4'
          data-testid='confirm-custom-request-button'
          disabled={!title || !description}
          aria-multiline
          onClick={() => {
            onConfirm({ title, description });
          }}
        >
          Add Custom Request
        </Button>
      </div>
    </GenericModal>
  );
}
