import { GenericModal } from '@/components/Modal';
import { Client, FileItem, User } from '@types';
import { useState, useEffect } from 'react';
import useAccountData from '@/hooks/useAccountData';
import useFileData from '@/hooks/useFileData';
import {
  CustomTabItemExtended,
  FileItemExtended,
  SyftItemExtended,
} from '@/components/UsersAccordionList/EditPermissionsModalSteps/interfaces';
import {
  getCheckedFileIdRecursive,
  getOnlySelectedFolders,
  transformCustomTabItemToCustomTabItemExtended,
  transformFileItemtoFileItemExtended,
  transformSyftTabToSyftTabExtended,
} from '@/components/UsersAccordionList/EditPermissionsModalSteps/utils';
import GenericWizard from '@/components/GenericWizard/GenericWizard';
import FilesStep from '@/components/UsersAccordionList/EditPermissionsModalSteps/FilesStep';
import SyftDashboardStep from '@/components/UsersAccordionList/EditPermissionsModalSteps/SyftDashboardStep';
import CustomTabsStep from '@/components/UsersAccordionList/EditPermissionsModalSteps/CustomTabsStep';
import OverviewStep from '@/components/UsersAccordionList/EditPermissionsModalSteps/OverviewStep';
import SelectUserStep from '@/components/GenericWizard/AddUsersSteps/SelectUserStep';
import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { patch } from '@/utils/api';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { getUserFullName } from '@/components/UserList/utils';

export default function AddUserModal({
  company,
  isOpen,
  onClose,
}: {
  company: Client;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { auth }: IUserContext = useUserContext();
  const { getAccountData, refreshAccountData } = useAccountData();
  const { getFileData } = useFileData();
  const { data: rootFolder, isValidating: isLoading, error } = getFileData();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const { data, error: accountError } = getAccountData(company.id, true);
  const customTabsConfig = (data?.config || []).find(
    (config) => config.config_key === 'custom_tab_options'
  );

  const syftUrl = (data?.config || []).find(
    (config) => config.config_key === 'syft_url'
  );

  const [customTabsState, setCustomTabsState] = useState<
    CustomTabItemExtended[]
  >(() => transformCustomTabItemToCustomTabItemExtended(customTabsConfig));

  const [syftUrlTabsState, setSyftUrlTabsState] = useState<SyftItemExtended[]>(
    () => transformSyftTabToSyftTabExtended(syftUrl)
  );

  const [filesState, setFilesState] = useState<FileItemExtended | null>(null);

  useEffect(() => {
    if (!isLoading) {
      const clientFolders: FileItem | undefined =
        !isLoading && rootFolder
          ? rootFolder.files.find((e: FileItem) => e.id === data?.ms_team_id)
          : undefined;
      setFilesState(
        clientFolders
          ? transformFileItemtoFileItemExtended(clientFolders)
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const updateCustomTabs = (customTabs: CustomTabItemExtended[]) => {
    setCustomTabsState(customTabs);
  };

  const updateSyftUrl = (syftTab: SyftItemExtended[]) => {
    setSyftUrlTabsState(syftTab);
  };

  const updateFiles = (files: FileItemExtended) => {
    setFilesState(files);
  };

  const getSelectedCustomTabs = () =>
    customTabsState.filter((tab) => tab.isChecked);

  const getSelectedSyftTab = () =>
    syftUrlTabsState.filter((tab) => tab.isChecked);

  const getSelectedFolders = () => getOnlySelectedFolders(filesState);

  const savePermissions = () => {
    setIsSaving(true);
    const selectedCustomTabsNames = getSelectedCustomTabs().map(
      (t) => t.titleOfTab
    );

    const selectedSyftUrl = getSelectedSyftTab().map((t) => t.syftUrl);

    const selectedFolderIds: string[] = [];
    if (filesState) {
      getCheckedFileIdRecursive(filesState, selectedFolderIds);
    }

    patch(`users/addClientAndPermissions`, {
      usersIds: [selectedUser?.id],
      clientId: company.id,
      filesPermissionsList: selectedFolderIds,
      syftPermissionUrls: selectedSyftUrl,
      customTabsPermissions: selectedCustomTabsNames,
    })
      .then(async () => {
        setIsSaving(false);
        await refreshAccountData(company.id);
        alertMessageSuccess('User added successfully');
        onClose();
      })
      .catch(() => {
        alertErrorMessage('Something went wrong. Please try again.');
        setIsSaving(false);
      });
  };

  if (error || accountError) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <ErrorAlert
            isSuperAdmin={auth?.isSuperAdmin}
            error={error as unknown}
          />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} className='max-w-[35%]'>
      <GenericWizard disableStep={isSaving || isDisable}>
        {/* <SelectUserTypeStep
          company={company}
          title='User Type'
          disableNext={setIsDisable}
          onClose={onClose}
        /> */}
        <SelectUserStep
          company={company}
          title='User'
          disableNext={setIsDisable}
          updateUser={setSelectedUser}
          onClose={onClose}
        />
        <FilesStep
          displayName={getUserFullName(selectedUser as User)}
          folderList={filesState as FileItemExtended}
          updateFiles={updateFiles}
          title='Files'
          isLoading={isLoading}
        />
        <SyftDashboardStep
          syftDashboardOptions={syftUrlTabsState}
          updateSyftDashboardOptions={updateSyftUrl}
          title='Dashboard'
        />
        <CustomTabsStep
          customTabs={customTabsState}
          displayName={getUserFullName(selectedUser as User)}
          updateCustomTabs={updateCustomTabs}
          title='Custom Tabs'
        />
        <OverviewStep
          customTabs={getSelectedCustomTabs()}
          folderList={getSelectedFolders().files}
          syftDashboardOption={getSelectedSyftTab()}
          isSaving={isSaving}
          title='Overview'
          onSave={savePermissions}
        />
      </GenericWizard>
    </GenericModal>
  );
}
