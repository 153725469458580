import { InvestorReportDTO, InvestorReportTemplateDTO } from '@types';
import { DateTime } from 'luxon';
import { TableSort } from '@/types';
import {
  ReportStatuses,
  ReportsFrequencies,
} from '../../../../../shared/constants';

export const getStatusColor = (report: InvestorReportDTO) => {
  switch (report.status) {
    case ReportStatuses.open:
      return 'yellow';
    case ReportStatuses.partial:
      return 'yellow';
    case ReportStatuses.completed:
      return 'green';
    case ReportStatuses.closed:
      return 'green';
    case ReportStatuses.overdue:
      return 'red';
    case ReportStatuses.deleted:
      return 'gray';
    default:
      return 'gray';
  }
};

export const getCardTitleColor = (status: ReportStatuses) => {
  switch (status) {
    case ReportStatuses.open:
      return 'error';
    case ReportStatuses.completed:
      return 'accent';
    case ReportStatuses.closed:
      return 'accent';
    case ReportStatuses.overdue:
      return 'error';
    case ReportStatuses.deleted:
      return 'error';
    default:
      return 'error';
  }
};

export const formatDate = (date?: string) => {
  if (date) {
    return DateTime.fromISO(date).toFormat('MM/dd/yyyy');
  }
  return ' - ';
};

export interface InvestorListProps {
  investorReports: InvestorReportDTO[];
  recordsPerPage: number;
  selectedPage: number;
  onPageSelect: (page: number) => void;
  onSelectRowsChange: (page: number) => void;
  searchText: string;
  investorReportTemplates: InvestorReportTemplateDTO[];
}

export interface RequestListProps extends InvestorListProps {
  sortBy: (fieldName: keyof InvestorReportingTableData) => void;
  sort: TableSort<InvestorReportingTableData>;
}

export interface InvestorReportingTableData extends InvestorReportDTO {
  company_name: string;
  investor_name: string;
  last_investor_update: string;
  next_request_date: string;
  firm_name: string;
}

export interface InvestorReportsDetailsData {
  investor_name: string;
  investor_reports: InvestorReportDTO[];
  selected_investor_report?: InvestorReportDTO;
}

export interface ReportsGroupedData {
  key: string;
  client_id: number;
  firm_id?: number;
  user_id?: number;
  company_name: string;
  investor_name: string;
  investorReports: InvestorReportDTO[];
  investorReportTemplates: InvestorReportTemplateDTO[];
}

export interface InvestorReportingGroupedTableData extends ReportsGroupedData {
  last_investor_update: string;
  next_request_date: string;
  overdueReports: InvestorReportDTO[];
  due_date?: string;
}

export const generateReportsTableData = (
  investorReports: InvestorReportDTO[],
  investorReportTemplates: InvestorReportTemplateDTO[]
) => {
  const records: ReportsGroupedData[] = [];
  investorReports.forEach((report) => {
    const key = `${report.client_id}_${report.firm_id || ''}_${
      report.user_id || ''
    }`;
    const reportTableData = records.find((r) => r.key === key);
    if (!reportTableData) {
      records.push({
        key,
        client_id: report.client_id,
        firm_id: report.firm_id,
        user_id: report.user_id,
        company_name: report.client?.client_name || '',
        investor_name: report.firm_id
          ? report.firm?.firm_name || ''
          : `${report.user?.first_name as string} ${
              report.user?.last_name as string
            }`,
        investorReports: [report],
        investorReportTemplates: [],
      });
    } else {
      reportTableData.investorReports.push(report);
    }
  });
  investorReportTemplates.forEach((template) => {
    const key = `${template.client_id}_${template.firm_id || ''}_${
      template.user_id || ''
    }`;
    const reportTableData = records.find((r) => r.key === key);
    if (!reportTableData) {
      records.push({
        key,
        client_id: template.client_id,
        firm_id: template.firm_id,
        user_id: template.user_id,
        company_name: template.client?.client_name || '',
        investor_name: template.firm_id
          ? template.firm?.firm_name || ''
          : `${template.user?.first_name as string} ${
              template.user?.last_name as string
            }`,
        investorReports: [],
        investorReportTemplates: [template],
      });
    } else {
      reportTableData.investorReportTemplates.push(template);
    }
  });
  return records;
};

export const findTemplateForReport = (
  investorReportTemplates: InvestorReportTemplateDTO[],
  report: InvestorReportDTO
) => {
  if (!investorReportTemplates || !report) return undefined;
  return investorReportTemplates.find(
    (template) =>
      template.frequency === report?.frequency &&
      template.client_id === report?.client_id &&
      template.firm_id === report?.firm_id &&
      template.user_id === report?.user_id
  );
};

export const resolveNextRequestDate = (
  report: InvestorReportDTO,
  template: InvestorReportTemplateDTO | undefined
) => {
  const now = DateTime.now();
  const date = DateTime.fromISO(report.created_at as unknown as string);
  switch (report.frequency) {
    case ReportsFrequencies.monthly: {
      if (!template) return ' - ';
      const currentStartingDate = DateTime.local(
        now.year,
        now.month,
        template?.starting_date || 1
      );
      const createdAt = DateTime.fromISO(
        report.created_at as unknown as string
      );
      let nexRequestDate = '';
      if (currentStartingDate > createdAt) {
        nexRequestDate = currentStartingDate.toFormat('MM/dd/yyyy');
      } else {
        nexRequestDate = currentStartingDate
          .plus({ month: 1 })
          .toFormat('MM/dd/yyyy');
      }
      return nexRequestDate;
    }

    case ReportsFrequencies.quarterly: {
      const currentQuarter = Math.ceil(date.month / 3);

      const currentQuarterStart = DateTime.fromObject({
        year: date.year,
        month: (currentQuarter - 1) * 3 + 1,
        day: 1,
      }).startOf('day');

      const nextQuarterStart = currentQuarterStart.plus({ months: 3 });
      return nextQuarterStart.toFormat('MM/dd/yyyy');
    }

    case ReportsFrequencies.annual: {
      const currentYearStart = DateTime.fromObject({
        year: date.year,
        month: 1,
        day: 1,
      }).startOf('day');

      const nextYearStart = currentYearStart.plus({ years: 1 });
      return nextYearStart.toFormat('MM/dd/yyyy');
    }

    case ReportsFrequencies.oneTime:
      return ' - ';

    default:
      return ' - ';
  }
};

const generateURLForReportDetails = (
  baseURL: string,
  clientNameFromQuery: string,
  client_id?: number,
  firm_id?: number,
  user_id?: number,
  report_id?: number,
  isPastUpdate?: boolean
) => {
  const clientName = encodeURIComponent(clientNameFromQuery);
  const clientId = client_id ? encodeURIComponent(client_id) : '';
  const firmId = firm_id ? encodeURIComponent(firm_id) : '';
  const userId = user_id ? encodeURIComponent(user_id) : '';
  const reportId = report_id ? encodeURIComponent(report_id) : '';

  let url = baseURL;
  url += clientNameFromQuery
    ? `?clientName=${clientName}&clientId=${clientId}`
    : `?clientId=${clientId}`;
  url += `&firmId=${firmId}&userId=${userId}&reportId=${reportId}`;
  url += clientNameFromQuery || isPastUpdate ? `&showPastUpdates=true` : '';
  return url;
};

export const generateURLForReportDetailsV1 = (
  clientNameFromQuery: string,
  client_id?: number,
  firm_id?: number,
  user_id?: number,
  report_id?: number,
  isPastUpdate?: boolean
) => {
  return generateURLForReportDetails(
    `/i-reporting-details`,
    clientNameFromQuery,
    client_id,
    firm_id,
    user_id,
    report_id,
    isPastUpdate
  );
};

export const generateURLForReportDetailsV2 = (
  clientNameFromQuery: string,
  client_id?: number,
  firm_id?: number,
  user_id?: number,
  report_id?: number,
  isPastUpdate?: boolean
) => {
  return generateURLForReportDetails(
    `/i-new-reporting-details`,
    clientNameFromQuery,
    client_id,
    firm_id,
    user_id,
    report_id,
    isPastUpdate
  );
};
