/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { Tabs } from 'react-daisyui';
import type {
  AccountDataSection,
  AccountData,
  User,
  KVPairs,
  ClientConfig,
  PlanData,
  DealData,
  EventLog,
  QboInvoices,
  QboConfigData,
  ClientInternalNote,
  FirmsWithUserDTO,
} from '@types';
import { get } from '@/utils/queries';
import { Spinner } from '@/components/Spinner';
import UsersAccordionList from '@/components/UsersAccordionList/UsersAccordionList';
import { AdminUsersAccordionList } from '@/components/AdminUsersAccordionList/AdminUsersAccordionList';
import { AccountConfig } from '@/components/AccountDetails/AccountConfig';
import { EmptyState } from '@/components/EmptyState';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { TableSort } from '@/types';
import useUsersList from '@/hooks/useUsersList';
import useInvoicesData from '@/hooks/useInvoices';
import useAccountData from '@/hooks/useAccountData';
import useInternalNotes from '@/hooks/useClientInternalNote';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { checkAPIError, prettifyKey } from '@/utils';
import FirmsAccordionList from '@/components/firmsAccordionList/firmsAccordionList';
import { AccountDataDisplay } from './AccountDataDisplay';
import { AccountPlanDetails } from './AccountPlanDetails';
import UserEventLogs from './components/UserEventLogs/UserEventLogs';
import { EventLogsTableData } from './components/UserEventLogs';
import Invoices from './components/Invoices/Invoices';
import { InvoiceTableData } from './components/Invoices';

const { Tab } = Tabs;

export function AccountDetailsLoader({
  clientId,
  tab = 'overview',
  test = false,
  onTabChange,
}: {
  clientId: number;
  tab: AccountDataSection;
  test?: boolean;
  onTabChange: (AccountDataSection: string) => void;
}) {
  const { getAccountData } = useAccountData();
  const { getUsersListByClientId } = useUsersList();
  const { getInvoicesData } = useInvoicesData();
  const { addInternalNotes, updateInternalNotes, deletedInternalNotes } =
    useInternalNotes();
  const {
    data: clientData,
    isValidating,
    error: accountError,
  } = getAccountData(clientId);
  const { data: clientUsersData, error: clientError } =
    getUsersListByClientId(clientId);
  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    error: invoicesError,
  } = getInvoicesData(clientId);
  const detailedData = clientData?.hubspot_data?.detailedData;

  const qboConfigurationData = clientData?.qbo_configuration_data
    ? clientData?.qbo_configuration_data
    : ({} as QboConfigData);

  const [eventLogSort, setEventLogSort] = useState<
    TableSort<EventLogsTableData>
  >({
    field: 'action' as keyof EventLogsTableData,
    asc: true,
  });
  const [invoiceSort, setInvoiceSort] = useState<TableSort<InvoiceTableData>>({
    field: 'action' as keyof InvoiceTableData,
    asc: true,
  });
  const [searchText, setSearchText] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(5);
  const [showError, setShowError] = useState<boolean>(false);

  const {
    data,
    isLoading,
    error: eventLogError,
  } = get<{ data: EventLog[]; count: number }>(
    `logs/${clientId}?page=${
      selectedPage - 1
    }&pageSize=${recordsPerPage}&q=${searchText}&sortField=${
      eventLogSort.field
    }&sortDirection=${eventLogSort.asc ? 'asc' : 'desc'}`
  );
  const logData = data?.data;

  if (
    checkAPIError([accountError, clientError, invoicesError, eventLogError])
  ) {
    if (!showError) {
      alertErrorMessage(`You don't have access to this account.`);
      setShowError(true);
    }
  }

  const addNewNotes = async (note: string) => {
    try {
      const { notes, message } = await addInternalNotes(clientId, note);
      alertMessageSuccess(message);

      return notes;
    } catch (e) {
      alertErrorMessage('There was an error adding the note');
      throw e;
    }
  };

  const updatedInternalNotes = async (internalNote: ClientInternalNote) => {
    try {
      const { notes, message } = await updateInternalNotes(
        clientId,
        internalNote.id,
        internalNote.internal_note
      );
      alertMessageSuccess(message);

      return notes;
    } catch (e) {
      alertErrorMessage('There was an error updating the note');
      throw e;
    }
  };

  const deletedNote = async (noteId: string) => {
    try {
      await deletedInternalNotes(clientId, noteId);
      alertMessageSuccess('Note deleted successfully.');
    } catch (e) {
      alertErrorMessage('There was an error deleting the note');
    }
  };

  if (isValidating) {
    return <Spinner className='mx-auto w-16 mt-10' />;
  }

  const currentClientData = {
    id: clientId,
    hubspot_id: clientData?.hubspot_id,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(detailedData as any),
    config: clientData ? clientData?.config ?? ({} as ClientConfig) : undefined,
    users: clientUsersData?.filter((user) => user.enabled),
    firms: clientData?.firms,
  } as AccountData;

  const filteredClientUserData = (clientUsersData || []).filter(
    (user) => user.enabled
  );
  if (!currentClientData) {
    return <EmptyState name='Account Data' />;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <AccountDetails
      clientData={currentClientData}
      logData={logData || []}
      invoicesData={invoicesData || []}
      clientUsersData={filteredClientUserData || []}
      test={test}
      tab={tab}
      onTabChange={onTabChange}
      eventLogSort={eventLogSort}
      invoiceSort={invoiceSort}
      searchText={searchText}
      selectedPage={selectedPage}
      recordsPerPage={recordsPerPage}
      setEventLogSort={setEventLogSort}
      setInvoiceSort={setInvoiceSort}
      setSelectedPage={setSelectedPage}
      setRecordsPerPage={setRecordsPerPage}
      setSearchText={setSearchText}
      isLoading={isLoading}
      clientQboConfigurationData={qboConfigurationData}
      isInvoicesLoading={isInvoicesLoading}
      addNewNotes={addNewNotes}
      updatedInternalNotes={updatedInternalNotes}
      deletedInternalNote={deletedNote}
    />
  );
}

const TAB_ORDER = {
  overview: 1,
  users: 2,
  firms: 3,
  config: 4,
  logs: 5,
  invoices: 6,
};

type TabNamesTypes = 'overview' | 'users' | 'config' | 'logs' | 'invoices';

export function AccountDetails({
  clientData,
  clientUsersData,
  logData,
  invoicesData,
  tab = 'overview',
  test = false,
  onTabChange,
  eventLogSort,
  invoiceSort,
  searchText,
  selectedPage,
  recordsPerPage,
  setEventLogSort,
  setInvoiceSort,
  setSelectedPage,
  setRecordsPerPage,
  setSearchText,
  isLoading,
  isInvoicesLoading,
  clientQboConfigurationData,
  addNewNotes,
  updatedInternalNotes,
  deletedInternalNote,
}: {
  clientData: AccountData;
  clientUsersData: User[];
  logData: EventLog[];
  invoicesData: QboInvoices[];
  tab: AccountDataSection;
  test?: boolean;
  onTabChange: (tabName: AccountDataSection) => void;
  eventLogSort: TableSort<EventLogsTableData>;
  invoiceSort: TableSort<InvoiceTableData>;
  searchText: string;
  selectedPage: number;
  recordsPerPage: number;
  setEventLogSort: (sort: TableSort<EventLogsTableData>) => void;
  setInvoiceSort: (sort: TableSort<InvoiceTableData>) => void;
  setSelectedPage: (page: number) => void;
  setRecordsPerPage: (records: number) => void;
  setSearchText: (str: string) => void;
  isLoading: boolean;
  isInvoicesLoading: boolean;
  clientQboConfigurationData: QboConfigData;
  addNewNotes: (notes: string) => Promise<ClientInternalNote[]>;
  updatedInternalNotes: (
    notes: ClientInternalNote
  ) => Promise<ClientInternalNote[]>;
  deletedInternalNote: (noteId: string) => Promise<void>;
}) {
  const { auth }: IUserContext = useUserContext();
  const size = 'sm';

  const tabTitles = ['overview', 'users', 'config'];

  if (auth?.isSuperAdmin || auth?.isAdmin) {
    tabTitles.push('logs');
    tabTitles.push('invoices');
    tabTitles.push('firms');
  }

  const selectedTab: AccountDataSection = (
    tabTitles?.filter((t) => tab === t)?.length > 0 ? tab : tabTitles[0]
  ) as AccountDataSection;
  const [activeTab, setActiveTab] = useState<AccountDataSection>(selectedTab);
  return (
    <>
      <div className='flex justify-between items-center'>
        <Tabs
          variant='bordered'
          value={activeTab}
          onChange={(tabName) => {
            setActiveTab(tabName);
            onTabChange(tabName);
          }}
          data-testid='account-details-tabs'
        >
          {tabTitles
            .sort(
              (t1, t2) =>
                TAB_ORDER[t1 as TabNamesTypes] - TAB_ORDER[t2 as TabNamesTypes]
            )
            .map((title) => (
              <Tab
                key={title}
                value={title}
                data-testid={`tab_${title}`}
                className='capitalize'
              >
                {prettifyKey(title)}
              </Tab>
            ))}
        </Tabs>
      </div>

      {activeTab === 'logs' ? (
        logData !== undefined ? (
          <div
            className='p-4 max-h-[calc(100vh-180px)] overflow-y-auto'
            data-testid='tab_logs'
          >
            <UserEventLogs
              logData={logData}
              sort={eventLogSort}
              searchText={searchText}
              selectedPage={selectedPage}
              recordsPerPage={recordsPerPage}
              setSort={setEventLogSort}
              setSelectedPage={setSelectedPage}
              setRecordsPerPage={setRecordsPerPage}
              setSearchText={setSearchText}
              isLoading={isLoading}
            />
          </div>
        ) : (
          <div className='p-4'> No logs Found </div>
        )
      ) : null}

      {activeTab === 'invoices' && !invoicesData ? (
        <div className='p-4'> No Invoices Available </div>
      ) : null}

      {activeTab === 'invoices' && invoicesData ? (
        <div
          className='p-4 max-h-[calc(100vh-180px)] overflow-y-auto'
          data-testid='tab_invoices'
        >
          <Invoices
            auth={auth}
            invoicesData={invoicesData}
            sort={invoiceSort}
            selectedPage={selectedPage}
            recordsPerPage={recordsPerPage}
            setSort={setInvoiceSort}
            setSelectedPage={setSelectedPage}
            setRecordsPerPage={setRecordsPerPage}
            isLoading={isInvoicesLoading}
            clientQboConfigurationData={clientQboConfigurationData}
            addNewNotes={addNewNotes}
            updatedInternalNotes={updatedInternalNotes}
            deleteInternalNote={deletedInternalNote}
          />
        </div>
      ) : null}
      {activeTab === 'config' && !clientData[activeTab] ? (
        <div className='p-4'> No Config Available </div>
      ) : null}
      {activeTab === 'config' && clientData[activeTab] ? (
        <div className='p-4 max-h-[calc(100vh-180px)] overflow-y-auto'>
          <AccountConfig
            config={clientData[activeTab] as unknown as ClientConfig[]}
            clientId={clientData.id}
          />
        </div>
      ) : null}
      {activeTab === 'overview' &&
      !clientData.business_info &&
      !clientData.plan ? (
        <div className='p-4'> No Data Found </div>
      ) : null}
      {activeTab === 'overview' &&
      (clientData.business_info || clientData.plan) ? (
        <div className='max-h-[calc(100vh-250px)] overflow-y-auto ml-4 p-4'>
          {clientData.business_info ? (
            <AccountDataDisplay
              companyData={clientData.business_info as KVPairs | KVPairs[]}
              clientId={clientData.id}
              hubspotId={clientData.hubspot_id}
            />
          ) : null}
          {clientData.plan ? (
            <AccountPlanDetails
              plan={clientData.plan as unknown as PlanData}
              size={size}
              showHubspotLink
              deals={clientData.plan.deals as unknown as DealData[]}
            />
          ) : null}
        </div>
      ) : null}
      {activeTab === 'users' && clientData[activeTab] ? (
        <div className='p-4 max-h-[calc(100vh-180px)] overflow-y-auto'>
          <UsersAccordionList
            clientData={clientData}
            users={clientData[activeTab] as unknown as User[]}
            size={size}
            showHubspotLink
            showDeleteButton
            test={test}
          />
          {clientUsersData ? (
            <AdminUsersAccordionList
              users={clientUsersData as unknown as User[]}
              size={size}
            />
          ) : null}
        </div>
      ) : null}
      {activeTab === 'overview' && clientData.plan ? (
        <div className='text-center'>
          <a
            href='https://meetings.hubspot.com/adelle-barte'
            target='_blank'
            className='btn btn-accent btn-sm mt-5 w-[200px]'
            rel='noreferrer'
          >
            Change plan
          </a>
        </div>
      ) : null}
      {activeTab === 'firms' && clientData[activeTab] ? (
        <div className='p-4 max-h-[calc(100vh-180px)] overflow-y-auto'>
          <FirmsAccordionList
            clientData={clientData}
            firms={clientData[activeTab] as unknown as FirmsWithUserDTO[]}
            size={size}
            showHubspotLink={auth?.isAdmin}
          />
        </div>
      ) : null}
    </>
  );
}
