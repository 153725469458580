import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { faChartLine, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GenericModal } from '@/components/Modal';
import { Auth, IUserContext, useUserContext } from '@/context/UserContext';
import { Client, InvestorReportDTO, InvestorReportTemplateDTO } from '@types';
import { get } from '@/utils/queries';
import { LoadingPage } from '@/pages/LoadingPage';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { get as getApi } from '@/utils/api';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import FeatureToggle from '@/components/FeatureToggle';
import { PastUpdates } from '@/pages/InvestorReporting/components/Investors/PastUpdates';
import { setInvestorReportSignal } from '@/pages/InvestorReporting/utils/investorReportDataSignal';
import { InvestorReportingManagement } from './InvestorReportingManagement';
import RequestBuilder from './components/modals/RequestBuilder';

const templateURL = (
  auth: Auth,
  impersonatingAccountId: number | null
): string => {
  const basePath = 'investorReports/templates';

  if (auth.isInvestor) return basePath;

  if (auth.isClient) {
    const clientId = auth.clients?.[0]?.id;
    if (!clientId) {
      throw new Error('Client ID is missing for client user');
    }
    return `${basePath}/client/${clientId}`;
  }

  if (auth.isAdmin) {
    if (!impersonatingAccountId) {
      throw new Error('Impersonating account ID is required for admin');
    }
    return `${basePath}/client/${impersonatingAccountId}`;
  }

  return basePath;
};

interface Props {
  removeImpersonatingAccountIdForInvestors?: boolean;
}

function InvestorReporting({
  removeImpersonatingAccountIdForInvestors,
}: Props) {
  const {
    auth,
    companyName,
    impersonatingAccountId,
    setimpersonatingAccountId,
  }: IUserContext = useUserContext();
  const navigate = useNavigate();
  const [showRequestBuilderModal, setShowRequestBuilderModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientNameFromQuery = queryParams.get('clientName') || '';
  const showPastUpdates = queryParams.get('showPastUpdates');

  queryParams.delete('clientName');
  queryParams.delete('showPastUpdates');
  const newUrl = `${window.location.origin}/i-reporting`;
  window.history.replaceState({}, '', newUrl);

  const [currentView, setCurrentView] = useState<
    'activeRequests' | 'pastUpdates'
  >(clientNameFromQuery || showPastUpdates ? 'pastUpdates' : 'activeRequests');

  useEffect(() => {
    setCurrentView(
      clientNameFromQuery || showPastUpdates ? 'pastUpdates' : 'activeRequests'
    );
  }, [clientNameFromQuery, showPastUpdates]);

  useEffect(() => {
    if (
      removeImpersonatingAccountIdForInvestors &&
      setimpersonatingAccountId &&
      auth?.isInvestor
    ) {
      setimpersonatingAccountId(null);
    }
  }, [
    removeImpersonatingAccountIdForInvestors,
    setimpersonatingAccountId,
    auth?.isInvestor,
  ]);
  const {
    data: investorReports,
    isValidating: isLoadingReports,
    error: reportsError,
    mutate: mutateReports,
  } = get<InvestorReportDTO[]>(
    impersonatingAccountId
      ? `investorReports?clientId=${impersonatingAccountId}`
      : 'investorReports'
  );

  const {
    data: allInvestorReports,
    isValidating: isLoadingAllReports,
    error: allReportsError,
    mutate: mutateAllReports,
  } = get<InvestorReportDTO[]>(
    impersonatingAccountId
      ? `investorReports/all?clientId=${impersonatingAccountId}`
      : 'investorReports/all'
  );

  const {
    data: investorReportTemplates,
    isValidating: isLoadingReportTemplates,
    error: reportTemplatesError,
    mutate: mutateReportTemplates,
  } = get<InvestorReportTemplateDTO[]>(
    templateURL(auth as Auth, impersonatingAccountId)
  );

  const { data: companyData } = auth?.isClient
    ? get<Client[]>(`account/${auth.clients[0].id}`)
    : get<Client[]>('account/list');

  const toggleView = () => {
    if (currentView === 'activeRequests') {
      navigate('/i-reporting?showPastUpdates=true');
    } else {
      navigate('/i-reporting');
    }
  };
  // #region SC-2794 - Trigger report creation from template
  const triggerGenerateReports = async () => {
    try {
      await getApi('investorReports/createReportsFromTemplate');
      alertMessageSuccess(
        'Report generation in progress. Please wait a few minutes and then refresh the tab.'
      );
    } catch (err) {
      alertErrorMessage('Error generating reports.');
    }
  };
  // #endregion

  if (
    (!investorReports && isLoadingReports) ||
    (!allInvestorReports && isLoadingAllReports) ||
    (!investorReportTemplates && isLoadingReportTemplates)
  ) {
    return <LoadingPage hasNav />;
  }

  setInvestorReportSignal({
    reportData: investorReports,
    isReportsLoading: isLoadingReports,
    reportsError,
    mutateReports,
  });

  if (reportsError || allReportsError || reportTemplatesError) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <ErrorAlert
            isSuperAdmin={auth?.isSuperAdmin}
            error={reportsError as unknown}
          />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  const props = {
    ...(companyName && { companyName }),
    ...(companyData && { companyData }),
  };

  return (
    <PageContainer name='investor-account'>
      <DashboardWrapper {...props}>
        <div className='p-4'>
          <div className='flex w-full justify-between items-center mb-4'>
            <h1 className='text-lg font-bold uppercase'>
              <FontAwesomeIcon
                icon={faChartLine}
                size='lg'
                className='mr-4 text-white'
              />
              Investor Reporting
            </h1>
            {auth?.isInvestor ? (
              <div className='text-lg font-bold uppercase text-right flex'>
                {/* #region SC-2794 - Trigger report creation from template */}
                <FeatureToggle featureTag='generate-reports' filterByRole>
                  <Button
                    color='accent'
                    size='sm'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                      await triggerGenerateReports();
                    }}
                    className='mr-2'
                  >
                    <FontAwesomeIcon icon={faRefresh} className='mr-1' />
                    Generate reports
                  </Button>
                </FeatureToggle>
                {/* #endregion */}
                <Button
                  color='accent'
                  size='sm'
                  onClick={() => {
                    setShowRequestBuilderModal(true);
                  }}
                  className='mr-2'
                >
                  Request builder
                </Button>
                <Button color='accent' size='sm' onClick={toggleView}>
                  {currentView === 'activeRequests'
                    ? 'Past Updates'
                    : 'Active Requests'}
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        {auth?.isInvestor ? (
          <GenericModal
            isOpen={showRequestBuilderModal}
            onClose={() => setShowRequestBuilderModal(false)}
            className='max-w-[35%]'
          >
            <RequestBuilder
              onSave={async () => {
                setShowRequestBuilderModal(false);
                await mutateReports();
                await mutateAllReports();
              }}
            />
          </GenericModal>
        ) : null}

        {currentView === 'activeRequests' ? (
          <InvestorReportingManagement
            investorReports={investorReports || []}
            investorReportTemplates={investorReportTemplates || []}
            updateData={async () => {
              await mutateReports();
              await mutateReportTemplates();
            }}
          />
        ) : null}
        {currentView === 'pastUpdates' ? (
          <PastUpdates
            investorReports={allInvestorReports || []}
            investorReportTemplates={investorReportTemplates || []}
          />
        ) : null}
      </DashboardWrapper>
    </PageContainer>
  );
}

export default InvestorReporting;
