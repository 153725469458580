/* eslint-disable react/destructuring-assignment */
import { ReactNode } from 'react';
import { Button } from 'react-daisyui';
import { DateTime } from 'luxon';
import {
  InvestorReportDTO,
  InvestorReportTemplateDTO,
  InvestorReportCardDTO,
  ExtendedInvestorReportCardDTO,
} from '@types';
import { Column } from '@/components/Table/Table';
import { TableSort } from '@/types';
import {
  findTemplateForReport,
  formatDate,
  InvestorReportingGroupedTableData,
  ReportsGroupedData,
  resolveNextRequestDate,
  InvestorReportingTableData,
} from '../../utils/investorReports';

export interface OpenRequestsTableData extends Partial<InvestorReportDTO> {
  card_name: string;
  investor_name: string;
  due_date: string;
  answer_request: ReactNode;
  mark_as_closed: ReactNode;
}

export const columnsForClientsView = (
  displayDueDate: boolean
): Column<InvestorReportingGroupedTableData | InvestorReportingTableData>[] => {
  const columns: Column<
    InvestorReportingGroupedTableData | InvestorReportingTableData
  >[] = [
    {
      header: 'investor name',
      accessor: 'investor_name',
      sortable: true,
      Cell: (row) => (
        <div role='button' tabIndex={0}>
          {row.investor_name}
        </div>
      ),
    },
    {
      header: 'Last Investor Update',
      accessor: 'last_investor_update',
      sortable: true,
    },
    {
      header: 'Date of Next Request',
      accessor: 'next_request_date',
      sortable: true,
    },
  ];

  if (displayDueDate) {
    columns.push({
      header: 'Due Date',
      accessor: 'due_date',
      sortable: true,
    });
  }

  return columns;
};

export const columnsForOpenRequests: Column<OpenRequestsTableData>[] = [
  {
    header: 'Name',
    accessor: 'card_name',
    sortable: true,
  },
  {
    header: 'Investor',
    accessor: 'investor_name',
    sortable: true,
  },
  { header: 'Due Date', accessor: 'due_date', sortable: true },
  {
    header: '',
    accessor: 'answer_request',
    sortable: false,
  },
  {
    header: '',
    accessor: 'mark_as_closed',
    sortable: true,
  },
];

export const mapApiInvestorReportsDataToTableData = (
  values: InvestorReportDTO[],
  investorReportTemplates: InvestorReportTemplateDTO[]
) => {
  return values.map((value) => {
    const template = findTemplateForReport(investorReportTemplates, value);
    return {
      ...value,
      id: value.id,
      company_name: value.client?.client_name || '',
      investor_name: value.firm
        ? value.firm.firm_name
        : `${value.user?.first_name as string} ${
            value.user?.last_name as string
          }`,
      status: value.status,
      frequency: value.frequency,
      last_investor_update:
        formatDate(value.created_at as unknown as string) || '',
      next_request_date: template?.starting_date
        ? resolveNextRequestDate(value, template)
        : '',
      firm_name: value.firm?.firm_name || '',
      period: value.period,
    };
  });
};

const getSortValueForInvestorReportingTableData = (
  sortField: string,
  report: InvestorReportingTableData
) => {
  switch (sortField) {
    case 'name':
      return report?.firm
        ? report?.firm?.firm_name || ''
        : `${report?.user?.first_name as string} ${
            report?.user?.last_name as string
          }` || '';
    case 'last_investor_update':
      return report?.last_investor_update
        ? new Date(report?.last_investor_update).getTime()
        : 0;
    case 'next_request_date':
      return report?.next_request_date
        ? new Date(report?.next_request_date).getTime()
        : 0;
    case 'frequency':
      return report?.frequency;
    default:
      return report[sortField as keyof InvestorReportingTableData];
  }
};

export const sortForInvestorReportingTableData = (
  sort: TableSort<InvestorReportingTableData>
) => {
  return (a: InvestorReportingTableData, b: InvestorReportingTableData) => {
    const obj1 = getSortValueForInvestorReportingTableData(sort.field, a);
    const obj2 = getSortValueForInvestorReportingTableData(sort.field, b);
    if (obj1?.toString() && obj2?.toString() && obj1 > obj2)
      return sort.asc ? 1 : -1;
    return sort.asc ? -1 : 1;
  };
};

export const mapApiInvestorReportsGroupedToTableData = (
  values: ReportsGroupedData[]
): InvestorReportingGroupedTableData[] => {
  return values.map((value) => {
    const lastInvestorUpdate = value.investorReports
      .map((report) => DateTime.fromISO(report.created_at?.toString() || ''))
      .filter((date) => date !== null)
      .sort((a, b) => b.toMillis() - a.toMillis());

    const nextRequestData = value.investorReportTemplates
      .map((template) => {
        const report = value.investorReports.find(
          (r) =>
            r.frequency === template.frequency &&
            r.client_id === template.client_id &&
            r.firm_id === template.firm_id &&
            r.user_id === template.user_id
        );
        const nextDate = resolveNextRequestDate(
          report ||
            ({
              created_at: DateTime.now().toISO().toString(),
              frequency: template.frequency,
            } as InvestorReportDTO),
          template
        );
        return DateTime.fromFormat(nextDate, 'MM/dd/yyyy');
      })
      .filter((date) => date !== null && date.isValid)
      .sort((a, b) => a.toMillis() - b.toMillis());

    return {
      ...value,
      last_investor_update:
        lastInvestorUpdate[0]?.toFormat('MM/dd/yyyy') || 'N/A ',
      next_request_date: nextRequestData[0]?.toFormat('MM/dd/yyyy') || '',
    } as InvestorReportingGroupedTableData;
  });
};

export const mapOpenCardsDataToTableData = (
  values: ExtendedInvestorReportCardDTO[],
  answerRequest: (card: InvestorReportCardDTO) => void,
  markAsClosed: (card: InvestorReportCardDTO) => void
) => {
  return values.map((card) => ({
    id: card.id,
    card_name: card.title,
    investor_name: card.firm
      ? card.firm.firm_name
      : `${card.user?.first_name || ''} ${card.user?.last_name || ''}`,
    due_date: card.created_at
      ? formatDate(
          new Date(
            new Date(card.created_at).setDate(
              new Date(card.created_at).getDate() + 15
            )
          ).toISOString()
        )
      : ' - ',
    answer_request: (
      <Button color='accent' size='sm' onClick={() => answerRequest(card)}>
        Answer Request
      </Button>
    ),
    mark_as_closed: (
      <Button color='error' size='sm' onClick={() => markAsClosed(card)}>
        Mark as closed
      </Button>
    ),
  }));
};
